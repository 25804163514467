import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { send } from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Join = () => {
  const [State, setState] = useState({
    isModal:false,
    isModalOwner:false,
    modalData:""
});

const [toSend, setToSend] = useState({
  request_type:'',
  your_city: '',
  your_name: '',
  your_state: '',
  your_phone: '',
  your_email: '',
  your_address: '',
  // your_type: '',
  flatbed:'Empty',
  van:'Empty',
  stepdeck:'Empty',
  reefer:'Empty',
  your_type: 'USA',
});

const opeDrivernModal = () => {
  setToSend({ ...toSend, request_type: 'Driver' });
  setState({ isModal:true });
}

const opeOwnernModal = () => {
  setState({ isModalOwner:true });
  setToSend({ ...toSend, request_type: 'Owner' });
}


const onSubmit = (e) => {
  e.preventDefault();
  send(
    'service_r4do23x',
    'template_8srnxd9',
    toSend,
    'ou5LsvjgoWhKK9jej'
  )
    .then((response) => {
      toast.success('Message successfully sent!');
      // console.log('SUCCESS!', response.status, response.text);
    })
    .catch((err) => {
      toast.error('Error sending info...'); 
      // console.log('FAILED...', err);
    });

    if(State.isModal){
      setState({ isModal:false })
    }else{
      setState({ isModalOwner:false })
    }
};

const handleChange = (e) => {
  setToSend({ ...toSend, [e.target.name]: e.target.value });
};

  return (
    <div
      style={{ background: "#0b0b0d", color: "white" }}
      className=""
      id="join"
    >
     
      <section className="join">
        <div className="row "  >
           <div className="join-image col-md-6 "></div>
           <div className="join-section col-md-6" >
                   <div className="join-heading">
                      <p>JOIN THE TEAM</p>
                   </div>
                   <div className="join-text">
                   <p className="join-p">We are building diverse teams of brilliant people who want to innovate one of the largest industries.</p>
                   <p className="join-p">We have positions open for Driver or Owner Operated vehicles.</p>
                   </div>
                   <div className="join-btn">
                    
                     <button type="button" className="btn " style={{ background: "#C63A3A"}} onClick={()=>{ opeDrivernModal() }}>DRIVER POSITION (PREQUALIFY)</button>
                   </div>
                    
                     <button type="button" className="btn " style={{ background: "#0C527E"}} onClick={()=>{ opeOwnernModal() }}>OWNER OPERATOR (PREQUALIFY)</button>
               </div>  
               
               {/* <div class="col-md-6 custom-col-padding-both empty custom-background image-background" style="background-image: url(&quot;https://www.gigi2.svologistics.com/wp-content/uploads/2023/03/570854855.jpeg&quot;); height: auto; background-size: auto;"></div>  */}

          </div>


          <Modal className="modal-driver" show={State.isModal} centered onHide={()=>{ setState({ isModal:false }) }}>
                                {/* <Modal.Header>
                                <h1 <span class="first">DRIVER&nbsp;<span class="second"> POSITION</span>
                                    <h5 className="modal-title" id="staticBackdropLiveLabel">Modal title</h5>
                                    <button type="button" className="btn-close" onClick={()=>{ setState({ isModal:false }) }}  ></button>
                                </Modal.Header> */}

           <Modal.Body>                             
                <div className="modal-container" >
				              <form className="wpcf7-form " onSubmit={onSubmit} >
                         <div className="contact-form-wrapper">
                              <div className="title-row">	
                                  <h3>
                                    <span className="first">DRIVER</span>&nbsp;<span>POSITION</span>
                                  </h3>
                              </div>
                              <div className="subtitle-row">	
                                    <p>Please complete the form and someone will contact you</p>
                              </div>   
                              <div className="row-md-12 custom-row">
                                    <p>
                                      <input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"   onChange={handleChange} placeholder="*YOUR FULL NAME" value={toSend.your_name} type="text" name="your_name"/> 
                                    </p>
                              </div>
                              <div className="row-md-12 custom-row">
                                    <p>
                                      <input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" onChange={handleChange} placeholder="*ADDRESS" value={toSend.your_address} type="text" name="your_address"/>
                                    
                                    </p>
                                  </div>
                              <div className="row  custom-row">
                                 <div className="col-md-6 join-space">
                                    <p>    
                                      <input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" onChange={handleChange} placeholder="*CITY" value={toSend.your_city} type="text" name="your_city"/>  
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <p>
                                      <input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" onChange={handleChange} placeholder="*STATE/PROVIDENCE " value={toSend.your_state} type="text" name="your_state"/>
                                    </p>
                                </div>
                                  </div>
                                  <div className="row custom-row">
                                    <div className="col-md-6 join-space">
                                      <p>
                                      
                                          <input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" onChange={handleChange} placeholder="*PHONE NUMBER" value={toSend.your_phone} type="text" name="your_phone"/>
                                          
                                      </p>
                                    </div>
                                    <div className="col-md-6">
                                      <p>
                                      
                                          <input size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" onChange={handleChange} placeholder="*EMAIL" value={toSend.your_email} type="email" name="your_email"/>
                                          
                                      </p>
                                    </div>
                                  </div>
                                  {/* <div className="row-md-12 custom-row">
                                    <p>
                                      <input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" onChange={handleChange} placeholder="*ADDRESS" value={toSend.your_address} type="text" name="your_address"/>
                                    
                                    </p>
                                  </div> */}
                                  {/* <div className="row-md-12 custom-row">
                                    <p>
                                      <input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" onChange={handleChange} placeholder="*TYPE OF POSITION INTERESTED IN" value={toSend.your_type} type="text" name="your_type"/>
                                    
                                    </p>
                                  </div> */}
                        </div> 
                       <div className="equipment-wrapper">                               
                          <div className="row-md-12 custom-exp">
                                <p>*EQUIPMENT EXPERIENCE</p>
                          </div>                          
                          <span class="wpcf7-list-item ">
                              <label>
                                 <input class="check" type="checkbox" name="flatbed" value="Check" onChange={handleChange} />
                                 <span className="item-label">FLATBED</span>
                              </label>
                           </span>
                           <span class="wpcf7-list-item">
                               <label>
                                  <input type="checkbox" name="van" value="Check" onChange={handleChange}/>
                                  <span className="item-label">VAN</span>
                               </label>
                           </span>
                           <span class="wpcf7-list-item">
                               <label>
                                   <input type="checkbox" name="stepdeck" value="Check" onChange={handleChange}/>
                                   <span className="item-label">STEP DECK</span>
                               </label>
                           </span>
                           <span class="wpcf7-list-item last">
                               <label>
                                   <input type="checkbox" name="reefer" value="Check" onChange={handleChange}/>
                                   <span className="item-label">REEFER</span>
                               </label>
                           </span>       
                        </div>
                        <div className="row btn-footer"> 
                            <button type="submit" className="btn btn-driver">SUBMIT</button>
                        </div>
                      </form>       
              </div>
               </Modal.Body>
               </Modal>



              <Modal  show={State.isModalOwner} centered onHide={()=>{ setState({ isModalOwner:false }) }}>
                    <Modal.Body>
                                  
                <div className="modal-container" >
                    <form className="wpcf7-form " onSubmit={onSubmit} >

                         <div className="contact-form-wrapper">
                              <div className="title-row">	
                              <h3>
                                 <span className="second">OWNER</span>&nbsp;<span>OPERATOR</span>
                              </h3>
                              </div>
                              <div className="subtitle-row">	
                                <p>Please complete the form and someone will contact you</p>
                              </div>
                              
                              <div className="row-md-12 custom-row">
                                <p>
                                  <input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"   onChange={handleChange} placeholder="*YOUR FULL NAME" value={toSend.your_name} type="text" name="your_name"/>
                                 
                                </p>
                              </div>
                              <div className="row-md-12 custom-row">
                                <p>
                                  <input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" onChange={handleChange} placeholder="*ADDRESS" value={toSend.your_address} type="text" name="your_address"/>
                                 
                                </p>
                              </div>
                              <div className="row  custom-row">
                                <div className="col-md-6 join-space">
                                  <p>
                                    
                                      <input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" onChange={handleChange} placeholder="*CITY" value={toSend.your_city} type="text" name="your_city"/>
                                     
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                      <input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" onChange={handleChange} placeholder="*STATE/PROVIDENCE " value={toSend.your_state} type="text" name="your_state"/>
                                     
                                  </p>
                                </div>
                              </div>
                              <div className="row custom-row">
                                <div className="col-md-6 join-space">
                                  <p>
                                   
                                      <input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" onChange={handleChange} placeholder="*PHONE NUMBER" value={toSend.your_phone} type="text" name="your_phone"/>
                                      
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                   
                                      <input size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" onChange={handleChange} placeholder="*EMAIL" value={toSend.your_email} type="email" name="your_email"/>
                                      
                                  </p>
                                </div>
                              </div>
                              {/* <div className="row-md-12 custom-row">
                                <p>
                                  <input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" onChange={handleChange} placeholder="*ADDRESS" value={toSend.your_address} type="text" name="your_address"/>
                                 
                                </p>
                              </div> */}
                              {/* <div className="row-md-12 custom-row">
                                <p>
                                  <input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" onChange={handleChange} placeholder="*TYPE OF POSITION INTERESTED IN" value={toSend.your_type} type="text" name="your_type"/>
                                 
                                </p>
                              </div> */}
                         </div>  
                         <div className="equipment-wrapper">   
                              <div className="row-md-12 custom-exp">
                                <p>*EQUIPMENT EXPERIENCE
                                </p>
                                </div>
                                <span class="wpcf7-list-item ">
                              <label>
                                 <input class="check" type="checkbox" name="flatbed" value="Check" onChange={handleChange} />
                                 <span className="item-label">FLATBED</span>
                              </label>
                           </span>
                           <span class="wpcf7-list-item">
                               <label>
                                  <input type="checkbox" name="van" value="Check" onChange={handleChange}/>
                                  <span className="item-label">VAN</span>
                               </label>
                           </span>
                           <span class="wpcf7-list-item">
                               <label>
                                   <input type="checkbox" name="stepdeck" value="Check" onChange={handleChange}/>
                                   <span className="item-label">STEP DECK</span>
                               </label>
                           </span>
                           <span class="wpcf7-list-item last">
                               <label>
                                   <input type="checkbox" name="reefer" value="Check" onChange={handleChange}/>
                                   <span className="item-label">REEFER</span>
                               </label>
                           </span>       
                        </div>
                        <div className="row btn-footer"> 
                            <button type="submit" className="btn btn-owner">SUBMIT</button>
                        </div>
                      </form>        
                  </div>
            </Modal.Body>       
         </Modal>
               <ToastContainer position="bottom-right" autoClose={3000} />
        </section>
    </div>
  );
};

export default Join;

import React from "react";
// import Fade from "react-reveal/Fade";

const Cover = () => {
  return (
    <div className="bg-div" id="home">
      <div className="head mx-5">
        <div className="head-div mx-auto main-div">
          {/* <Fade top cascade> */}
              <div className="head-div-second-text">
                <p>EXPRESS DELIVERY</p>
              </div>
              <div className="call">
              <a href="#about">
              <button type="button" className="btn" onClick={()=>{ }}>LEARN MORE</button>
              </a>
              </div>
          {/* </Fade> */}
        </div>
      </div>
      {/* <Fade bottom> */}
        <span className="scroll-btn">
          <a href="#home">
            <span className="mouse">
              <span></span>
            </span>
          </a>
        </span>
      {/* </Fade> */}
    </div>
  );
};

export default Cover;


import React, { useState } from "react";
import { send } from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Contact = () => {

  const [toSend, setToSend] = useState({
    your_name: '',
    your_phone: '',
    your_email: '',
    your_message: '',
    your_type: 'USA',
  });

  const onSubmit = (e) => {
    e.preventDefault();
    send(
      'service_r4do23x',
      'template_494g0rh',
      toSend,
      'ou5LsvjgoWhKK9jej'
    )
      .then((response) => {
        toast.success('Message successfully sent!');
        // console.log('SUCCESS!', response.status, response.text);
      })
      .catch((err) => {
        toast.error('Error sending info...'); 
        // console.log('FAILED...', err);
      });

  };
  
  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    
<div className="contact-div" id="contact">
  {/* <Fade top cascade> */}
    <section className="container">
      <div className="row mt-0 pt-0">
        <div className="col-md-4 mt-0 pt-0 chat-space" >
          <div className="mt-0 pt-0">
        
          <div className="chat-heading">
            <p >LET'S CHAT</p>
            </div>
            <div className="chat-text">
            <p >Want to chat with one of our advisors?</p>
            <p >Give us a call at. </p>
            <p >1 614 743 0888</p>
          </div>
       
      </div>
    </div>

    <ToastContainer position="bottom-right" autoClose={3000} />

    <div className="col-md-8" >
             


<form className="wpcf7-form " onSubmit={onSubmit} >
<div className="contact-form-wrapper">
	<div className="row single-row">
		<div className="col-6 single-col">
			<p>
        <input size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"   onChange={handleChange} placeholder="*YOUR FULL NAME" value={toSend.your_name} type="text" name="your_name"/>
			</p>
		</div>
		<div className="col-6 single-col">
			<p>
        <input size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" onChange={handleChange} placeholder="*PHONE NUMBER" value={toSend.your_phone} type="text" name="your_phone"/>
			</p>
		</div>
	</div>
  <div className="row-12 single-col">
  <p>
        <input size="40" className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" onChange={handleChange} placeholder="*EMAIL" value={toSend.your_email} type="email" name="your_email"/>
			</p>
  </div>
	<div className="row">
		<div className="col-md-9 message-column">
			<p>
          <textarea cols="40" rows="3" className="wpcf7-form-control wpcf7-textarea" onChange={handleChange} placeholder="MESSAGE *" name="your_message"  value={toSend.your_message}>
            </textarea>
			</p>
		</div>
		<div className="col-md-3 submit-column">
			<p>
        <button type="submit" className="wpcf7-submit" >SEND</button>
			</p>
		</div>
	</div>
</div>

</form>

    </div>


    </div>
    </section>      
       {/* </Fade> */}
       <hr></hr>
       <div className=" copywrite">
       <p> © 2023 GIGI Express USA. </p>
       </div>
    </div>
   
  );
};

export default Contact;

import React from 'react';
import full from '../assets/img/full-truck.png'
import less from '../assets/img/less-truck.png'

const services = () => {
    return (
      <div
        style={{ background: "#efeae4", color: "black"}}
        className="main-div"
        id="services"
      >
        <section className=" services">
          <div className="row-12" >
            <div className="col-md-12 " >
              <div className="services-heading">
                   <p>OUR SERVICES</p>
              </div>
              <div className="services-subheading">
                   {/* <p>GIGI EXPRESS Services well diversified.</p> */}
              </div>
             </div>
          </div>
          <div className="row-12" >
                  <div className="service-text">
                     <p>We take pride in trasporting a wide variety of commodities, safely and on schedule, including steel, aluminium. lumber, machinery, produce, and building products. Our terminal network is one of the strongest in the industry, with representatives on hand in all major areas.</p>
                   </div>
          </div>

            <div class="row" >
              <div class="col-md-6 " >
              <div class="truck-image">
                <img src={full} class="left" title="full truck" alt="full truck" width="115" height="27"/>
              </div>
                    <div class="truck-heading">
                            <h6>FULL TRUCKLOAD</h6>                            
                        </div>
                        <div class="truck-text">
                          <p>Enough products (which can be bulk/liquid) fills a Full Truck Load, or a dedicated truck is preferred for a partial load due to safety or security considerations. </p>
                          </div>
              </div>
                <div class="col-md-6 " >
                         <div class="truck-image-less">
                            <img src={less} className="left" title="less truck.fw" alt="less truck.fw" width="115" height="27"/>
                            </div>
                                <div class="truck-heading">
                            <h6>LESS-THAN TRUCKLOAD</h6>                            
                        </div>
                        <div class="truck-text">
                          <p>Is a shipping service for relatively small loads or quantities of freight—between 150 and 15,000 pounds.</p>
                          </div>
                </div>
              </div> 
        </section>
      </div>
    );
}

export default services;

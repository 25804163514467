import React, { useRef } from 'react';
import { Nav, Navbar, Button } from 'react-bootstrap';
// import Fade from 'react-reveal/Fade'; 
// import logoW from "../assets/img/gigi_white.png";
// import logoB from "../assets/img/gigi_logo.png";

const NavbarComponent = () => {
   // const imgRef = useRef(null);

    

    const changeBackground = () => {
      let header = document.getElementsByClassName("header-nav")[0];
      if (window.scrollY < 200) {
        header.classList.remove("sticky");
       // imgRef.current.src = logoW;
      } else {
        header.classList.add("sticky");
       // imgRef.current.src = logoB;
      }
    };
    window.addEventListener("scroll", changeBackground);
    return (
      <>
        <Navbar
          className="header-nav "
          fixed="top"
          collapseOnSelect
          bg="dark"
          expand="lg"
        >
          {/* <Fade left cascade> */}
          <Navbar.Brand href="#home" className="ml-5" id="navLogo">
            {/* <img
              ref={imgRef}
              src={logoB}
              width="100"
              height="51"
            /> */}
            </Navbar.Brand>
          {/* </Fade> */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto mr-5">
              {/* <Fade right cascade> */}
              <Nav.Link className="px-3" href="#home">
                HOME
              </Nav.Link>
              <Nav.Link className="px-3" href="#about">
                ABOUT US
              </Nav.Link>
              <Nav.Link className="px-3" href="#services">
                OUR SERVICES
              </Nav.Link>
              <Nav.Link className="px-3" href="#join">
                JOIN OUR TEAM
              </Nav.Link>
              <Nav.Link className="px-3" href="#contact">
                LET'S CHAT
              </Nav.Link>
              {/* </Fade> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
}

export default NavbarComponent;
